import {   RiBook2Line, RiFacebookLine, RiImage2Line, RiSettings2Fill } from "react-icons/ri"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"

function SettingsScreen() {
    return (
        <NavCardList numOfCards={'six'}>
            <NavCard cardName={'App Settings'} navCardLink={'/appSettings'} ><RiSettings2Fill /></NavCard>
            <NavCard cardName={'Social Links'} navCardLink={'/socialLinks'} ><RiFacebookLine /></NavCard>
            <NavCard cardName={'Featured Videos'} navCardLink={'/featuredVideos'} ><RiImage2Line /></NavCard>
            <NavCard cardName={'Featured Blogs'} navCardLink={'/featuredBlogs'} ><RiBook2Line /></NavCard>
        </NavCardList>
    )
}

export default SettingsScreen