import { backendUrl, spacesBucketUrl } from "../Constants"

export const BASE_URL = backendUrl
export const IMAGE_URL = spacesBucketUrl
export const API_URL = BASE_URL + 'api/'

export const EMPLOYEES_API = API_URL + 'employees/'
export const DESIGNATIONS_API = API_URL + 'designations/'
export const EMPLOYEE_DESIGNATIONS_API = API_URL + 'employeeDesignations/'
export const CUSTOMERS_API = API_URL + 'customers/'

export const APP_SETTINGS_API = API_URL + 'appSettings/'
export const SLIDERS_API = API_URL + 'sliders/'
export const BLOGS_API = API_URL + 'blogs/'
export const ABOUTS_API = API_URL + 'abouts/'
export const SOCIAL_LINKS_API = API_URL + 'socialLinks/'
export const VIDEO_CATEGORIES_API = API_URL + 'videoCategories/'
export const VIDEOS_API = API_URL + 'videos/'
export const FEATURED_VIDEOS_API = API_URL + 'featuredVideos/'
export const FEATURED_BLOGS_API = API_URL + 'featuredBlogs/'

export const CONTACTS_API = API_URL + 'contacts/'

